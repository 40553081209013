
import Carousel from 'react-bootstrap/Carousel';


function Slider2(){
    return(
        <>
        <div className='mt-5 row overflow-hidden m-0 p-0'>   
            <div style={{width:"fit-content"}} className='m-auto'>
                        <h4 style={{ textAlign:"center"}}>
                            Conoce TJ COFFEE 
                        </h4>
                        <p style={{lineHeight:"0px", textAlign:"center"}}>
                        Nuestra l&iacute;nea de maquinas 
                        </p>
                        <p style={{lineHeight:"5px", textAlign:"center"}}>expendedoras de caf&eacute;</p>
            </div>
        </div>
       
        <div  className="row mt-0 m-auto">
            <Carousel variant="dark" className='p-5 pt-3 col-lg-4 m-auto' pause={false} fade>

                    <Carousel.Item key="6" >
                                <img
                                className="d-block m-auto "
                                src={require('../imagenes/item6.jpg')}
                                alt={"Imagen del equipo de vending machine 6"}
                                style={{width:"100%"}}
                                />
                    </Carousel.Item>
                    <Carousel.Item key="7">
                                <img
                                className="d-block m-auto "
                                src={require('../imagenes/item7.jpg')}
                                alt={"Imagen del equipo de vending machine 7"}
                                style={{width:"100%"}}
                                />
                    </Carousel.Item>
                    <Carousel.Item key="8" >
                                <img
                                className="d-block m-auto"
                                src={require('../imagenes/item8.jpg')}
                                alt={"Imagen del equipo de vending machine 8"}
                                style={{width:"100%"}}
                                />
                    </Carousel.Item>
                    <Carousel.Item key="9" >
                                <img
                                className="d-block m-auto "
                                src={require('../imagenes/item9.jpg')}
                                alt={"Imagen del equipo de vending machine 9"}
                                style={{width:"100%"}}
                                />
                    </Carousel.Item>
                    <Carousel.Item key="10" >
                                <img
                                className="d-block m-auto "
                                src={require('../imagenes/item10.jpg')}
                                alt={"Imagen del equipo de vending machine 10"}
                                style={{width:"100%"}}
                                />
                    </Carousel.Item>
                    <Carousel.Item key="11" >
                                <img
                                className="d-block m-auto "
                                src={require('../imagenes/item11.jpg')}
                                alt={"Imagen del equipo de vending machine 11"}
                                style={{width:"100%"}}
                                />
                    </Carousel.Item>
        
            </Carousel>
        </div >
        </>
    );
    
}
export default Slider2;   
