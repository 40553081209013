import '../estilos/about.css';
function About(props){

    return(
        <div style={{background: "linear-gradient(to left bottom, #0b757526 0.7%, #760a0a85)"}}>
        <div style={{height:"149px"}} className='mt-3 row overflow-hidden m-0 p-0'>   
            <div style={{width:"fit-content"}} className='m-auto'>
                        <h4>
                            ¿Por qu&eacute; FSVending 
                        </h4>
                        <p style={{lineHeight:"0px", textAlign:"center"}}>
                        es una excelente opci&oacute;n?
                        </p>
            </div>
        </div>
        <div style={{width:"fit-content"}} className="row  m-auto">
                <div style={{width:"fit-content"}} className='col-3 m-auto'>
                       
                    <img className='aboutr mb-5' alt={"Imagen del equipo de vending machine 8"} style={{width:"200px",margin:"auto", zIndex:"100"}} src={require('../imagenes/card1.png')}/>
                   
                </div>
                <div style={{width:"fit-content"}} className='col-3 m-auto'>
                       
                    <img className='aboutr mb-5' alt={"Imagen del equipo de vending machine 8"} style={{width:"200px",margin:"auto", zIndex:"100"}} src={require('../imagenes/card2.png')}/>
                   
                </div>
                <div style={{width:"fit-content"}} className='col-3 m-auto'>
                       
                    <img className='aboutr mb-5' alt={"Imagen del equipo de vending machine 8"} style={{width:"200px",margin:"auto", zIndex:"100"}} src={require('../imagenes/card3.png')}/>
                   
                </div>
                <div style={{width:"fit-content"}}  className='col-3 m-auto'>
                       
                    <img className='aboutr mb-5' alt={"Imagen del equipo de vending machine 8"} style={{width:"200px",margin:"auto", zIndex:"100"}} src={require('../imagenes/card4.png')}/>
                   
                </div>
           
                </div> 
            
        </div>
    
    );
}

export default About;
