import React from 'react'


function Footer(){
    return (
        <footer className="text-center text-lg-start bg-light text-muted">
        
        <section
          className="d-flex justify-content-center justify-content-lg-between p-3 border-bottom"
        >
        </section>
        
      
        
        <section className="">
          <div className="container text-center text-md-start mt-2">
            
            <div className="row mt-3">

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-1">

              </div>
              
      
              
              <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-md-0 mb-1">

                <p className="text-uppercase text-center fw-bold mb-4">
                  Contacto
                </p>
                <p style={{lineHeight:"1px"}} className="text-center">Llamanos al:</p>
                <p style={{lineHeight:"normal"}} className="text-center ">+52 664 243 8580</p>
                <p style={{lineHeight:"normal"}} className="text-center">o mandanos un correo a la direcci&oacute;n:</p>
                <p style={{lineHeight:"1px"}} className="text-center">
                  administracion@fastservicevending.com
                </p>
                
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-1">

              <p className="text-uppercase text-center fw-bold mb-4">
                Desde 2008
              </p>
              <div className='m-auto d-flex'>
              <img src={require("../imagenes/vetzooLogo2.png")}alt="logo" style={{width:"130px",filter:"invert(50%)",margin:"auto", zIndex:"100"}} />

              </div>
              

              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-1">

<p className="text-uppercase text-center fw-bold mb-4">
  desarrollado por
</p>
<div className='m-auto d-flex'>
<img src={require("../imagenes/adatia.png")}alt="hhhoi" style={{width:"130px",filter:"invert(50%)",margin:"auto", zIndex:"100"}} />

</div>
<p className="text-uppercase text-center fw-bold mt-4">
  <i className="fas fa-gem "></i>
</p>
</div>
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-1">

             
              

              </div>
              
            </div>
            
          </div>
        </section>
        
      
        
        <p className="text-center p-3 mb-0" style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}>
            
          
         
        </p>
        
      </footer>
    );
}

export default Footer;