
function Benefits(props){

    return(
        <>
            <div style={{width:"80%"}} className="row  m-auto">
                <div style={{width:"fit-content"}} className='col-12 m-auto'>
                       
                    <img alt={"Imagen del equipo de vending machine 8"} className='aboutr' style={{width:"100%",margin:"auto", zIndex:"100"}} src={require('../imagenes/benefits.png')}/>
                   
                </div>
            </div>
            
            
        </>
    
    );
}

export default Benefits;
