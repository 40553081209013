
//Estilos
import 'bootstrap/dist/css/bootstrap.min.css';

//Dependencias


//Componentes
import Footer from './componentes/Footer'
import Home from './layouts/public/Home';
import Header from './componentes/Header';


function App() {
 
  return (
    <div className="App">
      <Header/>
      <Home/>
      <Footer/>
    </div>
      
      
  );
  /*}*/
}

export default App;
