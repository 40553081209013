import '../estilos/about.css';
function Brands(props){

    return(
        <div style={{background: "linear-gradient(to right bottom, #760a0a85 0.7%, #0b757526)"}}>
        <div style={{height:"120px"}} className='mt-3 row overflow-hidden m-0 p-0'>   
            <div style={{width:"fit-content"}} className='m-auto'>
                        <h4>
                            Productos l&iacute;deres 
                        </h4>
                        <p style={{lineHeight:"0px", textAlign:"center"}}>
                        sin exclusividad de marcas
                        </p>
            </div>
        </div>
        <div style={{width:"fit-content"}} className="row  m-auto">
                <div style={{width:"fit-content"}} className='w-75 m-auto'>
                       
                    <img alt={"Imagen del equipo de vending machine 8"} className='aboutr mb-5' style={{width:"100%",margin:"auto", zIndex:"100"}} src={require('../imagenes/marcas.png')}/>
                   
                </div>
              
           
                </div> 
            
        </div>
    
    );
}

export default Brands;
