import '../estilos/about.css';
function About(props){

    return(
        <>
        
            <div style={{width:"fit-content"}} className="row my-5 m-auto">
                <div style={{width:"fit-content"}} className='col-4 m-auto'>
                       
                    <img className='aboutr'alt="hhdj" style={{width:"290px",margin:"auto", zIndex:"100"}} src={require('../imagenes/vendingMachine.png')}/>
                   
                </div>
                <div style={{width:"fit-content"}} className='col-8 m-auto'>
                    <div className="row m-auto">
                        <img className='aboutl' alt={"Imagen del equipo de vending machine 8"} style={{width:"600px",margin:"auto", zIndex:"100"}} src={require('../imagenes/mision.png')}/>
                    </div>
                    <div className="row mt-1 m-auto">
                        <img alt={"Imagen del equipo de vending machine 8"} className='aboutl' style={{width:"650px",margin:"auto", zIndex:"100"}} src={require('../imagenes/historia.png')}/>
                    </div>
                </div>
            </div>
            
            
        </>
    
    );
}

export default About;
