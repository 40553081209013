import About from "../../componentes/About";
import Benefits from "../../componentes/Benefits";
import Brands from "../../componentes/Brands";
import Quality from "../../componentes/Quality";
import Slider from "../../componentes/Slider";
import Slider2 from "../../componentes/Slider2";

function Home(props){

    return(
        <>
            <About/> 
            <Quality/>
            <Slider/> 
            <Benefits/>
            <Brands/>
            <Slider2/>         
        </>
    
    );
}

export default Home;
