import '../estilos/header.css';
function Header(props){

    return(
        <div className='row overflow-hidden header m-0 p-0'>
            
            <div style={{width:"fit-content"}} className="m-auto">
            
                <img alt={"Imagen del equipo de vending machine 8"} style={{width:"200px",margin:"auto", zIndex:"100"}} src={require('../imagenes/vetzooLogo.png')} className="mb-2"/>
            </div>
            <div style={{width:"fit-content"}} className='m-auto'>
                        <h4 >
                            Fast Service Vending
                        </h4>
                        <p style={{lineHeight:"0px", textAlign:"center"}}>
                            Un servicio de calidad
                        </p>
            </div>
        </div>
    
    );
}

export default Header;
