
import Carousel from 'react-bootstrap/Carousel';


function Slider(){
    return(
        <div  className="row mt-0 m-auto">
            <Carousel variant="dark" pause={false} fade>
                    <Carousel.Item key="1" >
                                <img
                                className="d-block m-auto "
                                src={require('../imagenes/item1.jpg')}
                                alt={"Imagen del equipo de vending machine 1"}
                                style={{width:"80%"}}
                                />
                    </Carousel.Item>
                    <Carousel.Item key="2">
                                <img
                                className="d-block m-auto "
                                src={require('../imagenes/item2.jpg')}
                                alt={"Imagen del equipo de vending machine 2"}
                                style={{width:"80%"}}
                                />
                    </Carousel.Item>
                    <Carousel.Item key="3" >
                                <img
                                className="d-block m-auto"
                                src={require('../imagenes/item3.jpg')}
                                alt={"Imagen del equipo de vending machine 3"}
                                style={{width:"80%"}}
                                />
                    </Carousel.Item>
                    <Carousel.Item key="4" >
                                <img
                                className="d-block m-auto "
                                src={require('../imagenes/item4.jpg')}
                                alt={"Imagen del equipo de vending machine 4"}
                                style={{width:"80%"}}
                                />
                    </Carousel.Item>
                    <Carousel.Item key="5" >
                                <img
                                className="d-block m-auto "
                                src={require('../imagenes/item5.jpg')}
                                alt={"Imagen del equipo de vending machine 5"}
                                style={{width:"80%"}}
                                />
                    </Carousel.Item>
        
            </Carousel>
        </div >
    );
    
}
export default Slider;   
